const themeConfig = {
  light: {
    backgroundLight: "#ffffff",
    backgroundDark: "rgb(234,234,234)",
    textDark: "#171717",
    text: "#525252",
    textLight: "#737373",
    textDisabled: "#d4d4d4",
    textUui: "#334155",
    textUuiSlateBlue: "#64748B",
    textInverted: "#F9FAFA",
    primaryDark: "#4f46e5",
    primary: "#7D2DF3",
    secondary: "#4E0DAF",
    primaryLight: "#6366f1",
    primaryLighter: "#BE93F9",
    success: "#009140",
    successBorder: "#75b691",
    successBackground: "#e2ffe3",
    warning: "#ffc021",
    error: "rgb(224, 49, 48)",
    errorBorder: "rgb(213,149,140)",
    errorBackground: "#ffe3e2",
    subtle: "#f3f4f6",
    uuiSoftMist: "#BFC5D2",
    uuiSoftSkyBlue: "#E5EEFE",
    uuiSkyBlu: "#3076FF",
    uuiLightBlueGray: "#E2E8F0",
    iconColor: "#7D2DF3",

    // uui bg Colors
    uuiBgDefault: "#ffffff",
    uuiBgEmphasis: "#E2E8F0",
    uuiBgSubtle: "#F8FAFC",
    uuiBgMuted: "#F8FAFC",
    uuiBgInverted: "#1E293B",
    uuiBgInfo: "#E2E8F0",
    uuiBgSuccess: "#Def7ec",
    uuiBgAttention: "#F2EAFF",
    uuiBgError: "#BE332A",
    uuiBgDarkError: "#752522",

    // uui border colors
    uuiBorder: "#E2E8F0",
    uuiBorderEmphasis: "#CBD5E1",
    uuiBorderSubtle: "#F1F5F9",
    uuiBorderMuted: "#F1F5F9",
    uuiBorderError: "#F3584E",

    // uui content/text colors
    uuiTextEmphasis: "#1E293B",
    uuiText: "#374151",
    uuiTextSubtle: "#6B7280",
    uuiTextMuted: "#94A3B8",
    uuiTextInverted: "#FFFFFF",
    uuiTextInfo: "#374151",
    uuiTextSuccess: "#348A47",
    uuiTextAttention: "#4E0DAF",
    uuiTextError: "#BE332A",
    uuiTextDarkError: "#752522",

    // uui brand colors
    uuiBrand: "#7D2DF3",
    uuiBrandEmphasis: "#6024BA",
    uuiBrandText: "#7D2DF3",

    uuiLinkColor: "#BB8DFF",
  },
  dark: {
    backgroundLight: "#101010",
    backgroundDark: "#121212",
    textDark: "#e0e0e0",
    text: "#bdbdbd",
    textLight: "#9e9e9e",
    textDisabled: "#616161",
    textUui: "#fff",
    textUuiSlateBlue: "#94a3b8",
    textInverted: "#171717",
    primaryDark: "#6366f1",
    primary: "#8b5cf6",
    secondary: "#7c3aed",
    primaryLight: "#a78bfa",
    primaryLighter: "#c4b5fd",
    subtle: "#27272a",
    uuiSoftMist: "#4b5563",
    uuiSoftSkyBlue: "#1e3a8a",
    uuiSkyBlu: "#60a5fa",
    uuiLightBlueGray: "#1e293b",
    iconColor: "#fff",
    
    // uuiBg Colors
    uuiBgDefault: "#101010",
    uuiBgEmphasis: "#0F0B1E",
    uuiBgSubtle: "#171717",
    uuiBgMuted: "#1E293B",
    uuiBgInverted: "#F8FAFC",
    uuiBgInfo: "#7d2df3",
    uuiBgSuccess: "#306339",
    uuiBgAttention: "#8e3b1f",
    uuiBgError: "#C02E2E",
    uuiBgDarkError: "#6A2222",

    // uui border colors
    uuiBorder: "#1E293B",
    uuiBorderEmphasis: "#1E293B",
    uuiBorderSubtle: "#171717",
    uuiBorderMuted: "#1E293B",
    uuiBorderError: "#aa2e26",

    // uui content/text colors
    uuiTextEmphasis: "#642AB9",
    uuiText: "#94A3B8",
    uuiTextSubtle: "#475569",
    uuiTextMuted: "#334155",
    uuiTextInverted: "#374151",
    uuiTextInfo: "#94A3B8",
    uuiTextSuccess: "#E2FBE8",
    uuiTextAttention: "#FCEED8",
    uuiTextError: "#F9E3E2",
    uuiTextDarkError: "#752522",

    // uui brand colors
    uuiBrand: "#7D2DF3",
    uuiBrandEmphasis: "#7D2DF3",
    uuiBrandText: "#FFFFFF",
    uuiLinkColor: "#BB8DFF",
  }
};

// Helper function to generate CSS variables
const generateCssVariables = (theme) => {
  return Object.entries(themeConfig[theme])
    .map(([key, value]) => `--${key}: ${value};`)
    .join('\n');
};

module.exports = {
  themeConfig,
  generateCssVariables
};
