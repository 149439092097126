import React, { createContext, useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
const { themeConfig } = require("../../theme.config.js");

const ThemeContext = createContext();

export const ThemeProvider = ({ children, initialTheme = 'light' }) => {
  const [darkMode, setDarkMode] = useState(initialTheme === 'dark');
  const router = useRouter();

  useEffect(() => {
    const urlTheme = router.query.theme;
    const storedTheme = localStorage.getItem('theme');
    
    if (urlTheme === 'dark' || urlTheme === 'light') {
      setDarkMode(urlTheme === 'dark');
      localStorage.setItem('theme', urlTheme);
    } else if (storedTheme === 'dark' || storedTheme === 'light') {
      setDarkMode(storedTheme === 'dark');
    } else {
      // If no theme is set in URL or localStorage, use the initial theme
      setDarkMode(initialTheme === 'dark');
      localStorage.setItem('theme', initialTheme);
    }
  }, [router.query.theme, initialTheme]);

  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
    localStorage.setItem('theme', darkMode ? 'dark' : 'light');
  }, [darkMode]);

  const toggleDarkMode = () => {
    const newTheme = !darkMode ? 'dark' : 'light';
    setDarkMode(!darkMode);
    updateUrlTheme(newTheme);
  };

  const setThemeFromUrl = (theme) => {
    setDarkMode(theme === 'dark');
    updateUrlTheme(theme);
  };

  const updateUrlTheme = (theme) => {
    const newQuery = { ...router.query, theme };
    router.push({ pathname: router.pathname, query: newQuery }, undefined, { shallow: true });
  };

  const theme = darkMode ? themeConfig.dark : themeConfig.light;

  return (
    <ThemeContext.Provider value={{ theme, darkMode, toggleDarkMode, setThemeFromUrl }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};