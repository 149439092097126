export const branding = {
    rootDomain: "graph8.com",
    productName: "Graph8",
    productCamel: "graph8",
    npmNamespace: "@jitsu",
    logo: (
      <svg width="64" height="64" viewBox="0 0 1080 1080" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="1080" height="1080" rx="200" fill="#0A0118"/>
        <path d="M675.649 655.024C675.649 729.576 614.442 790.249 539.236 790.249C475.875 790.249 420.592 746.479 406.412 685.628L325.102 698.794L323.487 699.15C344.308 800.214 435.131 873.52 539.236 873.52C660.751 873.52 759.471 775.659 759.471 655.202V629.046L675.649 642.569V655.202V655.024Z" fill="url(#paint0_linear_66_2)"/>
        <path d="M319 425.318C319 545.776 417.72 643.636 539.236 643.636C660.751 643.636 759.471 545.776 759.471 425.318C759.471 374.253 741.702 327.28 711.906 290.093H760.189V207H539.236C417.72 207 319 304.861 319 425.318ZM675.649 425.318C675.649 499.87 614.443 560.544 539.236 560.544C464.029 560.544 402.822 499.87 402.822 425.318C402.822 350.766 464.029 290.093 539.236 290.093C614.443 290.093 675.649 350.766 675.649 425.318Z" fill="url(#paint1_linear_66_2)"/>
        <defs>
        <linearGradient id="paint0_linear_66_2" x1="263.896" y1="750.927" x2="903.104" y2="210.005" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00CBEB"/>
        <stop offset="0.53" stop-color="#C375FF"/>
        <stop offset="1" stop-color="#FC63D7"/>
        </linearGradient>
        <linearGradient id="paint1_linear_66_2" x1="319" y1="425.318" x2="903.962" y2="207" gradientUnits="userSpaceOnUse">
        <stop stop-color="#00CBEB"/>
        <stop offset="0.53" stop-color="#C375FF"/>
        <stop offset="1" stop-color="#FC63D7"/>
        </linearGradient>
        </defs>
      </svg>
    ),
      classicLogo: (
          <svg width="100%" height="100%" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M105.331 0H0V105.366L22.6251 128H128V22.6345L105.331 0ZM108.721 82.0069C108.718 89.1031 105.899 95.9078 100.884 100.926C95.8681 105.943 89.0661 108.763 81.973 108.766H53.9189V82.0069H81.973V50.5147H108.721V82.0069Z"
                  fill="#AA00FF"
              />
          </svg>
      ),
      wordmark: (
          <>
              <svg width="100%" height="100%" viewBox="0 0 454 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M54.0631 86.1489C54.0607 86.6984 53.8407 87.2247 53.4513 87.6124C53.0619 88.0001 52.5347 88.2178 51.9852 88.2178H22.0408C21.4921 88.2178 20.9659 87.9999 20.5779 87.6119C20.1899 87.2239 19.972 86.6976 19.972 86.1489V74.0714H0V89.9963L18.1481 108.208H55.8053L74.026 89.9963V0H54.0631V86.1489Z"
                      fill="currentColor"
                  />
                  <path
                      d="M101.493 19.9901H121.801V88.2178H101.493V108.208H162.262V88.2178H141.791V19.9901H162.262V0H101.493V19.9901Z"
                      fill="currentColor"
                  />
                  <path d="M183.359 19.9901H205.436V108.208H225.426V19.9901H247.676V0H183.359V19.9901Z" fill="black"/>
                  <path
                      d="M284.871 0L266.659 18.2116V36.9314L320.758 68.5635V86.1489C320.756 86.6984 320.536 87.2247 320.147 87.6124C319.757 88.0001 319.23 88.2178 318.68 88.2178H288.736C288.187 88.2178 287.661 87.9999 287.273 87.6119C286.885 87.2239 286.667 86.6976 286.667 86.1489V74.0714H266.704V89.9963L284.916 108.208H322.582L340.794 89.9963V61.2952L286.649 29.663V22.059C286.651 21.511 286.87 20.9862 287.258 20.5987C287.645 20.2112 288.17 19.9925 288.718 19.9901H318.662C319.212 19.9901 319.739 20.2078 320.128 20.5955C320.518 20.9832 320.738 21.5095 320.74 22.059V34.1365H340.703V18.2116L322.492 0H284.871Z"
                      fill="currentColor"
                  />
                  <path
                      d="M433.739 0V86.1489C433.739 86.6976 433.521 87.2239 433.133 87.6119C432.745 87.9999 432.219 88.2178 431.67 88.2178H389.195C388.919 88.2307 388.644 88.186 388.387 88.0867C388.129 87.9874 387.895 87.8356 387.7 87.641C387.504 87.4464 387.352 87.2132 387.251 86.9563C387.151 86.6995 387.105 86.4245 387.117 86.1489V0H367.154V89.9963L385.365 108.208H435.508L453.702 89.9963V0H433.739Z"
                      fill="currentColor"
                  />
              </svg>
          </>
      ),
  } as const;
  